<template>
  <div class="upload-bar position-fixed bg-apply py-5 px-sm-5 d-flex w-100 bottom-0">
    <div class="container-xxl p-sm-0 d-flex">
      <div class="me-15">
        <ClientOnly>
          <ve-progress
            class="flex-shrink-0"
            :progress="progress"
            color="rgba(var(--bs-primary-rgb))"
            empty-color="rgba(var(--bs-apply-rgb))"
            size="62"
            thickness="5"
            animation="default 500 0"
            empty-thickness="5"
            hide-legend
          >
            <template #legend-caption>
              <span
                class="fw-bold"
                style="font-size: 18px"
              >
                {{ progress }}%
              </span>
            </template>
          </ve-progress>
        </ClientOnly>
      </div>
      <div class="d-flex flex-column justify-content-center">
        <div class="text-primary fw-bold">
          Загрузка видео...
        </div>
        <div style="font-size: 10px">
          Внимание! Дождитесь загрузки.<br>
          Не выходите с сайта!
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { UploadVideoData } from "~/entities/UploadVideoData";

const { $toast } = useNuxtApp();
const runtimeConfig = useRuntimeConfig();

//-----STATE-----\\
const uploadVideoList = useState<UploadVideoData[]>('uploadVideoList');
const progress = ref<number>(0);

//-----COMPUTED-----\\
watch(uploadVideoList, checkVideoList, { deep: true });

//-----METHODS-----\\
function upload(item: UploadVideoData) {
  item.upload.status = 1;
  const xhr = new XMLHttpRequest();

  xhr.upload.addEventListener('progress', (e) => {
    item.upload.size.loaded = e.loaded;
    item.upload.size.total = e.total;
  }, false);
  xhr.upload.addEventListener('error', () => {
    item.upload.status = -1;
  }, false);

  xhr.open('POST', `${runtimeConfig.public.protocol}://record.${runtimeConfig.public.domain}/api/record`, true);
  xhr.setRequestHeader('Authorization', 'Bearer ' + item.token);

  const form = new FormData();
  form.append('answerId', item.answerId);
  form.append('isVacancy', String(item.isVacancy));
  form.append('file', item.file);
  form.append('isWebm', String(item.isWebm));

  xhr.send(form);
}
function checkVideoList(newList: UploadVideoData[]) {
  if (newList.length === 0) {
    return;
  }

  let isAllLoaded = true;
  let loadedSize = 0;
  let totalSize = 0;
  let errCount = 0;
  for (let i = 0; i < newList.length; i++) {
    if (newList[i].upload.status === 0) {
      upload(newList[i]);
      isAllLoaded = false;
      continue;
    }
    if (newList[i].upload.status === -1) {
      errCount++;
      continue;
    }
    if (newList[i].upload.size.total === null) {
      isAllLoaded = false;
      continue;
    }

    loadedSize += newList[i].upload.size.loaded!;
    totalSize += newList[i].upload.size.total!;
  }

  if (totalSize !== 0) {
    progress.value = Math.round(loadedSize * 100 / totalSize);
  }

  if (loadedSize < totalSize) {
    isAllLoaded = false;
  }

  if (isAllLoaded) {
    if (errCount === 0) {
      $toast.success('Все видео успешно загружены');
    } else if (uploadVideoList.value.length !== errCount) {
      $toast.warning(`К сожалению не удалось загрузить ${errCount} из ${uploadVideoList.value.length}`);
    } else {
      $toast.error('К сожалению ни одно видео не удалось загрузить, попробуйте повторить позже');
    }

    uploadVideoList.value = [];
  }
}
function onBeforeUnload(e) {
  if (uploadVideoList.value.length !== 0) {
    return;
  }

  e = e || window.event;
  const text = 'Загрузка видео не завершена, если вы покинете страницу, прогресс будет потерян.';

  if (e) {
    e.returnValue = text;
  }

  return text;
}

onBeforeMount(() => {
  checkVideoList(uploadVideoList.value);
});
onMounted(() => {
  window.addEventListener('beforeunload', onBeforeUnload);
});
onBeforeUnmount(() => {
  window.removeEventListener('beforeunload', onBeforeUnload);
});
</script>

<style scoped lang="scss">
//@import 'vue-toastification/src/scss/index';

.upload-bar {
  height: 72px;
  z-index: 20;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
@media (min-width: 576px) {
  .upload-bar {
    width: 280px !important;
    bottom: 58px !important;
    right: 75px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }
}
</style>